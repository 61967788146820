import React, { useState, useEffect, useContext } from "react";
import { GoPlusCircle } from "react-icons/go";
import { MyContext } from "../context/Context";
import { postCotizacion } from "../controller/api";
import toast, { Toaster } from "react-hot-toast";
import { sendEmail } from "../controller/api";
export default function Calculadora({
  data,
  selectedServicios,
  selectedSecciones,
  selectedPaginas,
  selectedFunciones,
  selectedSeccionesMax,
  maxValue,
  limitReached,
  exceededPaginas,
  precio,
  onCheckboxChange,
}) {
  const { state } = useContext(MyContext);
  // Calcular el precio total y convertir "precio" de string a número
  const totalServicios = selectedServicios.reduce(
    (sum, item) => sum + parseFloat(item?.precio?.replace("$", "") || 0),
    0
  );
  const totalSecciones = selectedSeccionesMax.reduce(
    (sum, item) => sum + parseFloat(item?.precio?.replace("$", "") || 0),
    0
  );
  const totalPaginas = selectedPaginas.reduce(
    (sum, item) => sum + parseFloat(item?.precio?.replace("$", "") || 0),
    0
  );
  const totalPaginasExtra = exceededPaginas.reduce(
    (sum, item) => sum + parseFloat(item?.precio?.replace("$", "") || 0),
    0
  );
  const totalFunciones = selectedFunciones.reduce(
    (sum, item) => sum + parseFloat(item?.precio?.replace("$", "") || 0),
    0
  );
  console.log(state, "contexto");

  let total = totalSecciones + totalFunciones + precio + totalPaginasExtra;

  // Dependiendo del estado (state), restamos algunos valores
  // Mapeo de estados a descuentos
  const descuentos = {
    WebDeReservaciones: 400,
    PaginaCorporativa: 400,
    WebInformativa: 400,
    Blog: 400,
    PaginaDeMembresia: 450,
    Foro: 450,
    eCommerce: 600,
  };

  // Si el estado existe en el mapeo, restamos el descuento correspondiente
  if (descuentos[state]) {
    total -= descuentos[state];
  }

  // Otras condiciones para más estados
  // Puedes agregar más lógica dependiendo de lo que necesites

  const [formData, setFormData] = useState({
    Producto: "",
    funciones_mensuales: "",
    secciones: "",
    paginas: "",
    Funciones: "",
    secciones_extra: "",
    paginas_extra: "",
    total: 0,
    Cliente: "",
    email: "",
    company: "",
    position: "",
    comments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    setFormData((prev) => ({
      ...prev, // Correctamente envuelto en paréntesis
      Producto: state || "", // Asegura que `state` tenga un valor predeterminado si es null o undefined
      funciones_mensuales: (selectedServicios || [])
        .map((item) => item.fields?.Producto || "")
        .join(", "), // Convierte el arreglo en un string separado por comas
      secciones: (selectedSecciones || [])
        .map((item) => item.name || "")
        .join(", "),
      Funciones: (selectedFunciones || [])
        .map((item) => item.name || "")
        .join(", "),
      secciones_extra: (selectedSeccionesMax || [])
        .map((item) => item.name || "")
        .join(", "),
      paginas_extra: Object.entries(
        (exceededPaginas || []).reduce((acc, item) => {
          const name = item.name || item.paginas || "";
          acc[name] = (acc[name] || 0) + 1; // Cuenta las repeticiones
          return acc;
        }, {})
      )
        .map(([name, count]) => (count > 1 ? `${name} x${count}` : name)) // Agrega "x2, x3" si hay repeticiones
        .join(", "), // Convierte el resultado en un string separado por comas

      paginas: Object.entries(
        (selectedPaginas || []).reduce((acc, item) => {
          const name = item.name || item.paginas || "";
          acc[name] = (acc[name] || 0) + 1; // Cuenta las repeticiones
          return acc;
        }, {})
      )
        .map(([name, count]) => (count > 1 ? `${name} x${count}` : name)) // Agrega "x2, x3" si hay repeticiones
        .join(", "), // Convierte el resultado en un string separado por comas

      total: total || 0, // Asegura que `total` sea un número, incluso si es undefined
    }));
  }, [
    state,
    selectedServicios,
    selectedSecciones,
    selectedPaginas,
    selectedFunciones,
    selectedSeccionesMax,
    exceededPaginas,
    total,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Llama a la función postCotizacion
      const responseCotizacion = await postCotizacion(formData);

      // Llama a la nueva función sendEmail
      const responseEmail = await sendEmail(formData);

      if (responseCotizacion && responseEmail) {
        setTimeout(() => {
          window.location.reload();
        }, 3000);

        // Mostrar mensaje de éxito con estilo personalizado
        toast("Cotización y correo enviados exitosamente", {
          icon: "🚀",
          style: {
            borderRadius: "10px",
            background: "#2C2C2C",
            color: "#fff",
          },
        });
      }
    } catch (error) {
      console.error("Error al enviar la cotización o el correo:", error);

      // Mostrar mensaje de error con estilo personalizado
      toast(
        "Ocurrió un error al enviar la cotización o el correo. Inténtalo nuevamente.",
        {
          icon: "❌",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }
      );
    }
  };

  return (
    <div className="calculator-container">
      <Toaster />
      <div className="calculator-content">
        <div className="formulario">
          <form className="custom-form" onSubmit={handleSubmit}>
            {[
              { label: "Nombre", name: "Cliente", type: "text" },
              { label: "Correo Electrónico", name: "email", type: "email" },
              { label: "Nombre de la Empresa", name: "company", type: "text" },
              { label: "Cargo que Ocupa", name: "position", type: "text" },
            ].map((field) => (
              <div className="form-group" key={field.name}>
                <label
                  htmlFor={field.name}
                  className={`form-label ${
                    formData[field.name] ? "active" : ""
                  }`}
                >
                  {field.label}
                </label>
                <input
                  id={field.name}
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  className="form-input"
                />
              </div>
            ))}

            {/* Section for Comments and Submit */}
            <div className="form-group full-width">
              <label
                htmlFor="comments"
                className={`form-label ${formData.comments ? "active" : ""}`}
              >
                Comentarios
              </label>
              <textarea
                id="comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                className="form-input"
              ></textarea>
            </div>

            <div className="form-footer">
              {/* <div className="price-section">
                <p className="price">${total.toFixed(2)}</p>
              </div> */}
              <button type="submit" className="quote-button">
                Enviar Cotización
              </button>
            </div>
          </form>
        </div>
        <div className="items-section">
          <h3 className="section-title">
            <strong>
              {selectedServicios.length > 0 ? "Servicios Mensuales" : ""}
            </strong>
          </h3>
          <ul className="items-list">
            {selectedServicios.length > 0 ? (
              selectedServicios.map((item) => (
                <li key={`servicio-${item.fields.ID}`} className="item-name">
                  {item.fields.Producto}
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
          {selectedSecciones.length > 0 && (
            <div className="secciones-container">
              {" "}
              {/* Clase añadida */}
              <h3 className="section-title">
                <strong>Secciones</strong>
              </h3>
              <ul className="items-list">
                {selectedSecciones.map((item, index) => {
                  return (
                    <li key={`seccion-${item.ID}`} className="item-name">
                      {item.name} {item["Secciones de portada básicas "]}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {selectedSeccionesMax.length > 0 && (
            <div className="extra-sections-container">
              {" "}
              {/* Clase añadida */}
              <h3 className="section-title">
                <strong>Secciones Extra</strong>
              </h3>
              <ul className="items-list">
                {selectedSeccionesMax.map((item) => (
                  <li
                    key={`max-seccion-${item.ID}`}
                    className="item-name extra-item flex justify-center"
                  >
                    {item.name}
                    <GoPlusCircle className="icon-extra-section" />{" "}
                    {/* Clase añadida */}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <h3 className="section-title">
            <strong>{selectedPaginas.length > 0 ? "Paginas" : ""}</strong>
          </h3>
          <ul className="items-list">
            {selectedPaginas.length > 0 ? (
              Object.entries(
                selectedPaginas.reduce((acc, item) => {
                  acc[item.ID] = acc[item.ID] || { ...item, count: 0 };
                  acc[item.ID].count += 1;
                  return acc;
                }, {})
              ).map(([id, item]) => (
                <li key={`pagina-${id}`} className="item-name">
                  {item.name ? item.name : item.paginas}{" "}
                  {item.count > 1 ? `x${item.count}` : ""}
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
          {exceededPaginas.length > 0 && (
            <>
              <h3 className="section-title">
                <strong>
                  {exceededPaginas.length > 0 ? "Paginas Adisionales" : ""}
                </strong>
              </h3>
              <ul className="items-list">
                {Object.entries(
                  exceededPaginas.reduce((acc, item) => {
                    acc[item.ID] = acc[item.ID] || { ...item, count: 0 };
                    acc[item.ID].count += 1;
                    return acc;
                  }, {})
                ).map(([id, item]) => (
                  <li key={`pagina-${id}`} className="item-name extra-item">
                    {item.name ? item.name : item.paginas}{" "}
                    {item.count > 1 ? `x${item.count}` : " "}{" "}
                    <GoPlusCircle className="icon-extra-page" />{" "}
                    {/* Clase añadida */}
                  </li>
                ))}
              </ul>
            </>
          )}
          <h3 className="section-title">
            <strong>
              {selectedFunciones.length > 0 ? "Funciones Adicionales" : ""}
            </strong>
          </h3>
          <ul className="items-list">
            {selectedFunciones.length > 0 ? (
              selectedFunciones.map((item) => (
                <li key={`pagina-${item.ID}`} className="item-name">
                  {item.name}
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
          <p className="total-price">Total: ${total.toFixed(2)}</p>{" "}
          {/* Clase añadida */}
        </div>
      </div>
    </div>
  );
}
